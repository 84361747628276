import React, { Component } from "react"
import styled from "styled-components"
import { customSizes } from "./variables"
import { customAnimations, fadeOut, fadeIn } from "../components/animations"

const CountdownWrapper = styled.div`
display: flex;
flex-direction: row;
position: absolute;
z-index: 1;
mix-blend-mode: lighten;
top: 50%;
left: 50%;
transform: translate3D(-50%, -50%, 0);

&.show {
  animation: ${fadeIn} ${customAnimations.speedSlow} ${customAnimations.easeInOut} forwards;
}
&.hide {
  animation: ${fadeOut} ${customAnimations.speedSlow} ${customAnimations.easeInOut} forwards;
  display: none;
}

> ul {
  display: flex;
  flex-direction: row;
  margin 0;
  padding: 0;
  > li {
    display: inline-block;
    list-style-type: none;
    margin 0 0 0 ${customSizes.magicNumber};
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    &:nth-child(1) {
      margin-left: 0;
    }
    > .dateNumber {
      margin: 0;         
    }
    > .dateIndicator {
      margin-bottom: calc(${customSizes.magicNumber} / 2);
      display: block;
      width: fit-content;

    }
  }
}

@media (min-width: calc(${customSizes.breakTablet} / 1.5)) and (max-width: ${customSizes.breakDesktop}) and (orientation: landscape) {
  > ul {
    > li {
      > .dateNumber {
        font-size: 18vw;
      }
      > .dateIndicator {
        font-size: 4vw;
      }
    }
  }
}

@media (max-width: ${customSizes.breakTablet}) and (orientation: portrait) {
  top: calc(50% - ${customSizes.heightSpotifyPlayer});
  margin: 0;
  > ul {
    flex-wrap: wrap;
    > li {
      margin: 0;
      width: calc(50% - ${customSizes.magicNumber});
      &:nth-child(odd) {
        margin-right: calc(${customSizes.magicNumber} * 2);
      }
      > .dateNumber {
        font-size: 28vw;
      }
      > .dateIndicator {
        font-size: 6vw;

      }
    }
  }
}
`
class Countdown extends Component {
  componentDidMount() {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24

    const countDownWrapper = document.getElementById("countDownWrapper")
    const vimeoWrapper = document.getElementById("vimeoWrapper")
    const spotifyWrapper = document.getElementById("spotifyWrapper")

    let countDown = new Date(
      this.props.date || "Oct 18, 2019 17:00:00"
    ).getTime()

    let x = setInterval(function() {
      let now = new Date().getTime()
      let distance = countDown - now

      function insertNumber(element, calc) {
        function addZero(n) {
          return n < 10 ? "0" + n : "" + n
        }
        return (document.getElementById(element).innerText = addZero(calc))
      }

      insertNumber("cdDays", Math.floor(distance / day))
      insertNumber("cdHours", Math.floor((distance % day) / hour))
      insertNumber("cdMinutes", Math.floor((distance % hour) / minute))
      insertNumber("cdSeconds", Math.floor((distance % minute) / second))

      // document
      //   .getElementById("destroyButton")
      //   .addEventListener("click", showVideo)

      function showVideo() {
        console.log("We're live 🚀")
        countDownWrapper.classList.add("hide")
        spotifyWrapper.classList.add("hide")
        vimeoWrapper.classList.add("show")
      }
      if (distance < 0) {
        clearInterval(x)
        showVideo()
      }
    }, second)
  }
  render() {
    return (
      <>
        <CountdownWrapper id="countDownWrapper">
          <ul>
            <li>
              <p className="hashtag dateIndicator">Days</p>
              <span id="cdDays" className="dateNumber">
                88
              </span>
            </li>
            <li>
              <p className="hashtag dateIndicator">Hours</p>
              <span id="cdHours" className="dateNumber">
                88
              </span>
            </li>
            <li>
              <p className="hashtag dateIndicator">Minutes</p>
              <span id="cdMinutes" className=" dateNumber">
                88
              </span>
            </li>
            <li>
              <p className="hashtag dateIndicator">Seconds</p>
              <span id="cdSeconds" className=" dateNumber">
                88
              </span>
            </li>
          </ul>
        </CountdownWrapper>
        {/* <button id="destroyButton">Destroy countdown <span>⚠️</span></button> */}
      </>
    )
  }
}

export default Countdown
