import React from "react"
import styled from "styled-components"
import { customSizes } from "./variables"
import { customAnimations, appearIn } from "../components/animations"

import "./styling/layout.css"

const VimeoPlayer = ({ headline }) => {
  const VimeoWrapper = styled.div`
    width: auto;
    height: 100%;
    z-index: 10;
    text-align: center;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;

    .iframeWrapper {
      height: 100%;
      width: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .iframeWrapper iframe {
      width: 100%;
      height: 100%;
      padding: ${customSizes.magicNumber};
      z-index: 3;
      @media (max-width: ${customSizes.breakTablet}) {
        height: initial;
        min-height: 56.25vw;
      }
    }

    p {
      position: absolute;
      left: 50%;
      top: 52.5%;
      transform: translate3D(-50%, -50%, 0);
      z-index: -1;
    }

    &.show {
      animation: ${appearIn} ${customAnimations.speedSlow}
        ${customAnimations.easeInOut} forwards;
      display: flex;
    }
  `
  const Headline = styled.div`
    // mix-blend-mode: lighten;
    // position: absolute;
    // text-align: center;
    // top: calc(${customSizes.magicNumber} * -5);
    // left: 50%;
    // transform: translateX(-50%);
    // width: fit-content;
    // z-index: 2;
    @media (min-width: ${customSizes.breakTablet}) {
      display: none;
    }
  `

  return (
    <VimeoWrapper id="vimeoWrapper">
      <div className="iframeWrapper">
        <Headline>
          <h2>{headline}</h2>
        </Headline>
        <iframe
          title="Vimeo Player"
          src="https://player.vimeo.com/video/366481973?autoplay=1&title=0&byline=0&portrait=0"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      {/* <p>loading...</p> */}
    </VimeoWrapper>
  )
}

export default VimeoPlayer
