import { keyframes } from "styled-components"

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`
export const appearIn = keyframes`
  0% {
    display: none;
    opacity: 0;
    transform: scale(0.95);
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    display: block;
    opacity: 1;
    transform: scale(1);
  }
`

export const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
`

export const customAnimations = {
  easeInOut: "cubic-bezier(0,.26,.28,.89)",
  speedSlow: "690ms",
  speedMedium: "480ms",
  speedFast: "280ms",
}
