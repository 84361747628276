import React from "react"
import styled from "styled-components"
import { customSizes } from "./variables"

import "./styling/layout.css"

const SpotifyPlayer = () => {
  const IframeWrapper = styled.div`
    width: 100%;
    height: calc(${customSizes.magicNumber} * 1.5);
    z-index: 2;
    @media (max-width: ${customSizes.breakTablet}) and {
      display: none;
      height: calc(${customSizes.magicNumber} / 1.5);
    }
  `

  return (
    <IframeWrapper>
      <iframe
        title="Spotify Player"
        src="https://open.spotify.com/embed/playlist/59WvtJbUmNfglG7W6CpVsL"
        width="100%"
        height="80"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    </IframeWrapper>
  )
}

export default SpotifyPlayer
