export const customSizes = {
  magicNumber: "4vw",
  heightSpotifyPlayer: "80px",
  breakTablet: "767px",
  breakDesktop: "1024px",
  breakBigboy: "1920px",
}

export const customColors = {
  black: "#000000",
  white: "#ffffff",
}
