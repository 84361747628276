import React from "react"
import animationMp4 from "../assets/videos/axe-aerosol_purpred.mp4"
import animationWebM from "../assets/videos/axe-aerosol_purpred.webm"

const VideoBackground = () => {
  return (
    <video
      id="backgroundVideo"
      autoplay
      loop
      muted
      playsinline
      preload="auto"
    >
      <source src={animationMp4} type="video/mp4" />
      <source src={animationWebM} type="video/webm" />
    </video>
  )
}

export default VideoBackground
