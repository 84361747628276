import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import SpotifyPlayer from "../components/spotify-player"
import Countdown from "../components/countdown"
import VideoBackground from "../components/video-background"
import VimeoPlayer from "../components/vimeo-player"
import CookieConsent from "../components/cookie-consent"
import fallBackImage from "../assets/images/Axe_Aerosol_PurpRed.png"

import { customSizes } from "../components/variables"
import { customAnimations, fadeIn } from "../components/animations"

const Header = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: calc(${customSizes.magicNumber} / 2);
  @media (max-width: ${customSizes.breakTablet}) {
    padding-top: ${customSizes.magicNumber};
  }
  @media (min-width: ${customSizes.breakTablet}) and (max-width: ${customSizes.breakDesktop}) {
    padding-top: calc(${customSizes.magicNumber} * 2);
  }
`
const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 ${customSizes.magicNumber} calc(${customSizes.magicNumber} / 4)
    ${customSizes.magicNumber};
  background: black;
  @media (max-width: ${customSizes.breakTablet}) {
    height: calc(${customSizes.heightSpotifyPlayer} * 1.5);
    padding-bottom: calc(${customSizes.magicNumber} * 1);
  }
  @media (max-width: ${customSizes.breakDesktop}) and (orientation: landscape) {
    position: relative;
  }
`
const PageHome = styled.div`
  padding: 0 ${customSizes.magicNumber};
  width: 100%;
  min-height: 100vh;
  opacity: 0;
  transform: scale(0.95);
  animation: ${fadeIn} ${customAnimations.speedSlow}
    ${customAnimations.easeInOut} forwards;
  animation-delay: ${customAnimations.speedSlow};
  @media (max-width: ${customSizes.breakTablet}) {
    min-height: calc((var(--vh, 1vh) * 100));
  }
`

const Background = styled.div`
  height: 100%;
  height: calc((var(--vh, 1vh) * 100) - (${customSizes.magicNumber} * 4));
  position: relative;
  overflow: hidden;
  video {
    width: 100%;
    height: auto;
    background-image: url('${fallBackImage}');
    background-size: cover;
  }
  .gatsby-image-wrapper {
    height: 100%;
    width: auto;
  }
  @media (max-width: ${customSizes.breakDesktop}) and (orientation: portrait) {
    video {
      width: auto;
      height: 100%;
      position: absolute;
    }
  }
  @media (max-width: ${customSizes.breakTablet}) {
    // height: calc(100vh - (${customSizes.magicNumber} * 9));
    // height: calc((var(--vh, 1vh) * 100) - (${customSizes.magicNumber} * 9));
    video {
      width: auto;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      object-fit: cover;
    }

  }
`
const TopLogo = styled.div`
  width: calc(${customSizes.magicNumber} * 3);
  align-self: flex-end;
  position: relative;
  z-index: 1;
  @media (max-width: ${customSizes.breakTablet}) {
    width: calc(${customSizes.magicNumber} * 4);
  }
`
const BottomLogo = styled.div`
  width: calc(${customSizes.magicNumber} * 3);

  @media (max-width: ${customSizes.breakTablet}) {
    width: calc(${customSizes.magicNumber} * 6);
  }
`

const SpotifyWrapper = styled.div`
  z-index: 2;
  position: absolute;
  height: ${customSizes.heightSpotifyPlayer};
  right: ${customSizes.magicNumber};
  top: calc(${customSizes.magicNumber} / 2);
  border-radius: 5px;
  overflow: hidden;
  min-width: calc(${customSizes.magicNumber} * 12);

  @media (max-width: ${customSizes.breakDesktop}) and (max-aspect-ratio: 4/3) {
    width: calc(100vw - (${customSizes.magicNumber} * 4) + 2px);
    z-index: 2;
    position: absolute;
    bottom: calc(${customSizes.heightSpotifyPlayer} * 1.5);
    height: ${customSizes.heightSpotifyPlayer};
    top: initial;
    left: -1px;
    right: 0;
    margin: ${customSizes.magicNumber} calc(${customSizes.magicNumber} * 2);
  }
  &.hide {
    display: none;
  }
`
const HashtagWrapper = styled.div`
  // width: 100%;
  margin-right: calc(${customSizes.magicNumber} / 4);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${customSizes.breakTablet}) {
    flex-direction: column;
  }
`
const HashtagText = styled.span`
  margin-left: calc(${customSizes.magicNumber} / 2);
  @media (max-width: ${customSizes.breakTablet}) {
    width: fit-content;
    margin-left: 0;
    margin-top: calc(${customSizes.magicNumber} / 2);
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="AXE Music x STMPD RCRDS - LIVE - ADE 2019" />
    <PageHome>
      <SpotifyWrapper id="spotifyWrapper">
        <SpotifyPlayer />
      </SpotifyWrapper>
      <Header>
        <TopLogo>
          <Image imgName="axemusic-logo+white.png" />
        </TopLogo>
      </Header>
      <Background>
        <CookieConsent />
        <Countdown date="Oct 18, 2019 17:00:00" />
        <VimeoPlayer headline=" " />
        <VideoBackground />
      </Background>
      <Footer>
        <BottomLogo>
          <Image imgName="stmpd-logo_new+white.png" />
        </BottomLogo>
        <HashtagWrapper>
          <HashtagText>
            <h1 className="hashtag">#axemusic</h1>
          </HashtagText>
          <HashtagText>
            <h2 className="hashtag">#movesyoucloser</h2>
          </HashtagText>
        </HashtagWrapper>
      </Footer>
    </PageHome>
  </Layout>
)

export default IndexPage
