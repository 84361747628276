import React, { Component } from "react"
import styled from "styled-components"

import "./styling/layout.css"
import "./styling/typography.css"

const Main = styled.main`
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
`

class Layout extends Component {
  componentDidMount() {
    function autoPlayBackgroundVideoThanksToApple() {
      const videoElement = document.getElementById("backgroundVideo")

      async function playVideo() {
        try {
          await videoElement.play()
          videoElement.className = "playing"
        } catch (err) {
          videoElement.className = ""
        }
        if (videoElement.className !== "playing") {
          videoElement.play()
        }
      }
      playVideo()
    }
    function setTrueHeightThanksToApple() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", () => {
      setTrueHeightThanksToApple()
    })

    autoPlayBackgroundVideoThanksToApple()
    setTrueHeightThanksToApple()
  }

  render() {
    return <Main>{this.props.children}</Main>
  }
}

export default Layout
