import React, { Component } from "react"

import styled from "styled-components"
import { customSizes } from "./variables"
import { customAnimations, fadeOut } from "../components/animations"

const CookieWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: calc(${customSizes.magicNumber} / 1.5);
  left: 0;
  z-index: 10;
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc(${customSizes.magicNumber} / 2);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.65);
  &.hide {
    animation: ${fadeOut} ${customAnimations.speedSlow}
      ${customAnimations.easeInOut} forwards;
  }
  &.show {
    display: flex;
  }
  &:after {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  p {
    opacity: 0.65;
    font-size: 1rem;
    line-height: 1.25;
    font-family: "BebasNeueRegular";
    margin-right: calc(${customSizes.magicNumber} / 2);
    a {
      margin-left: 5px;
      color: white;
      text-decoration: underline;
    }
  }
  #cookieButton {
    background-color: rgba(255, 255, 255, 0.65);
    border: none;
    border-radius: 0;
    color: black;
    padding: 5px 5px 2.5px 5px;
    margin-top: 5px;
    cursor: pointer;
    min-width: 65px;
    margin-left: calc(${customSizes.magicNumber} / 2);
    text-align: center;
    font-family: "BebasNeueRegular";
    font-size: 1rem;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  @media (max-width: ${customSizes.breakTablet}) and (orientation: portrait) {
    top: 0;
    bottom: initial;
    padding-left: 0;
    p,
    #cookieButton {
      font-size: 0.85rem;
    }
  }
`

class CookieConsent extends Component {
  componentDidMount() {
    let GDPRConsent = GDPRConsent || {}

    GDPRConsent.cookiePolicy = (function(__M) {
      let cookiePolicy = document.querySelector("#cookieWrapper"),
        acceptCookieBtn = cookiePolicy.querySelector("#cookieButton"),
        closeCookiePolicy = cookiePolicy.querySelector("#cookieButton")

      closeCookiePolicy.addEventListener("click", function(ev) {
        ev.preventDefault()
        acceptCookie()
      })

      document.querySelector("body").addEventListener("click", function(ev) {
        acceptCookie()
      })

      acceptCookieBtn.addEventListener("click", function(ev) {
        ev.preventDefault()
        acceptCookie()
      })

      if (document.cookie.indexOf("cookie_notice_accepted") === -1) {
        cookiePolicy.classList.add("show")
      }

      function acceptCookie() {
        if (document.cookie.indexOf("cookie_notice_accepted") === -1) {
          cookiePolicy.classList.add("show")
          // set cookie
          let d = new Date()
          d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
          document.cookie =
            "cookie_notice_accepted=true" + ";expires=" + d.toGMTString()
        }

        if (cookiePolicy) {
          cookiePolicy.parentNode.removeChild(cookiePolicy)
          cookiePolicy = null
        }
      }
    })(GDPRConsent)
  }
  render() {
    return (
      <CookieWrapper id="cookieWrapper">
        <p>
          This website uses cookies to ensure you get the best experience on our
          website. By making use of our website or by clicking the approval
          button, you confirm and agree to our
          <a href="https://www.nomobo.tv/files/cookiepolicy_NOMOBO.pdf">
            cookie policy.
          </a>
        </p>
        <button id="cookieButton">Yes got it!</button>
      </CookieWrapper>
    )
  }
}

export default CookieConsent
